import axios from "~/plugins/axios";

export default {
  feed() {
    let url = "/ebsn/api/news/news-feed";
    return axios
      .get(url)
      .then(response => response.data.data.news)
      .catch(err => err);
  },
  list(onlyActive, page = 1, pageSize = 24, fromDate, toDate) {
    let url = "/ebsn/api/news/list";
    let params = {
      page: page,
      page_size: pageSize,
      from_date: fromDate,
      to_date: toDate,
      only_active: onlyActive
    };
    return axios
      .get(url, { params: params })
      .then(response => response.data.data.news)
      .catch(err => err);
  },
  markAsRead(newsIds) {
    let url = "/ebsn/api/news/read";

    return axios
      .post(url, null, { params: { news_id: newsIds } })
      .then(response => response.data.data.news)
      .catch(err => err);
  },
  cancel(newsIds) {
    let url = "/ebsn/api/news/cancel";

    return axios
      .post(url, null, { params: { news_id: newsIds } })
      .then(response => response.data.data.news)
      .catch(err => err);
  }
};
