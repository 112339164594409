<template>
  <div v-if="category" class="mb-3">
    <div v-if="!hideTitle" class="d-flex align-center flex-row flex-wrap">
      <v-btn
        v-if="selectedCategory && !isMobile"
        icon
        :to="{ name: 'Category', params: { pathMatch: category.slug } }"
        exact
        ><v-icon>$prev</v-icon></v-btn
      >
      <h1 style="line-height: 3rem" v-html="title"></h1>
      <strong class="ml-0 ml-sm-4" v-if="count > 0">
        ({{ count }} prodotti)
      </strong>
    </div>
    <div
      v-if="subtitle && headerImage"
      class="mt-2 mb-4 d-flex flex-column flex-sm-row white pa-3 rounded"
    >
      <div class="mb-4 mb-sm-0">
        <v-img
          v-if="headerImage"
          :src="headerImage"
          class="mr-0 mr-sm-3"
          @load="loaded = true"
          :alt="category.name"
        ></v-img>
      </div>
      <div class="">
        <div @click="clicked" v-html="subtitle"></div>
      </div>
    </div>
    <p v-else @click="clicked" :class="classes" v-html="subtitle"></p>
  </div>
</template>
<style scoped lang="scss">
p > a {
  color: var(--v-default-base) !important;
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";

import get from "lodash/get";

export default {
  name: "CategoryTitle",
  props: {
    count: { type: Number, required: false },
    category: { type: Object, required: true },
    selectedCategory: { type: Object, required: false },
    height: { type: String },
    showOnMobile: { type: Boolean, default: false }
  },
  mixins: [clickHandler],
  data: function() {
    return { loaded: false };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    title() {
      let c = this.selectedCategory ? this.selectedCategory : this.category;
      return get(c, "metaData.category_info.TITLE", c.name);
    },
    subtitle() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    },
    classes() {
      if (this.showOnMobile) {
        return ["subtitle d-block text-caption default--text"];
      } else {
        return ["subtitle d-none", "d-sm-block text-h5 default--text"];
      }
    },
    headerImage() {
      let prop =
        "metaData.category_info." +
        (!this.$vuetify.breakpoint.smAndDown
          ? "HEADER_IMAGE"
          : "HEADER_IMAGE_MOBILE");
      return get(
        this.category,
        prop,
        get(this.category, "metaData.category_info.HEADER_IMAGE")
      );
    },
    hideTitle() {
      return get(this.category, "metaData.category_info.HIDE_TITLE", false);
    }
  }
};
</script>
