<template>
  <v-dialog v-model="showDialog" max-width="500" @click="handleLink(news)">
    <news-card
      v-for="news in newsList"
      :key="news.newsId"
      :news="news"
      :show-opened="false"
      :show-read="true"
      @read="markAsRead(news.newsId)"
    />
  </v-dialog>
</template>
<script>
import newsService from "~/service/newsService";
import NewsCard from "./NewsCard.vue";

import remove from "lodash/remove";

export default {
  components: { NewsCard },
  name: "News",
  data() {
    return {
      newsList: [],
      showDialog: false
    };
  },
  props: { news: { type: Object, required: false } },
  methods: {
    async reload() {
      this.newsList = (await newsService.feed()) || [];
      this.showDialog = this.newsList.length > 0;
    },
    async markAsRead(id) {
      await newsService.markAsRead(id);
      remove(this.newsList, function(item) {
        return item.newsId == id;
      });
      this.showDialog = this.newsList.length > 0;
      console.log("Show: " + this.showDialog);
      console.log(this.newsList);
    }
  },
  watch: {
    news() {
      this.newsList = [this.news];
      this.showDialog = true;
    }
  },
  mounted() {
    if (this.news != null) {
      console.log("dfs");
      this.newsList = [this.news];
      this.showDialog = true;
    } else {
      this.reload();
    }
  }
};
</script>
